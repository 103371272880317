import React, { useEffect, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { S3Client, PutObjectCommand, GetObjectCommand } from '@aws-sdk/client-s3';
import { Buffer } from 'buffer';
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import AdminNav from './AdminNav';
import { useNavigate } from 'react-router-dom';
import { LAMBDA_URL } from '../utils/constants';
import { Image } from 'react-bootstrap';


const AddBlogPage = () => {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [content, setContent] = useState('');
  const [uploadedImages, setUploadedImages] = useState({});
  const [postContent, setPostContent] = useState('');
  const [responseMessage, setResponseMessage] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const navigate = useNavigate();


  // AWS credentials
  const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const REGION_NAME = process.env.REACT_APP_REGION_NAME;
  const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;

  // Create S3 client
  const s3Client = new S3Client({
    region: REGION_NAME,
    credentials: {
      accessKeyId: AWS_ACCESS_KEY_ID,
      secretAccessKey: AWS_SECRET_ACCESS_KEY,
    },
  });

  // Handle image upload
  // const handleImageUpload = async (image) => {
  //   const imageBytes = Buffer.from(image, 'base64');
  //   const params = {
  //     Bucket: BUCKET_NAME,
  //     Key: `${Date.now()}.jpg`,
  //     Body: imageBytes,
  //   };
  
  //   // Upload the image
  //   await s3Client.send(new PutObjectCommand(params));
  
  //   // Generate a presigned URL for the uploaded image
  //   const getObjectParams = {
  //     Bucket: BUCKET_NAME,
  //     Key: params.Key,
  //   };
  //   const presignedUrl = await getSignedUrl(s3Client, new GetObjectCommand(getObjectParams), {
  //     expiresIn: 60 * 60 * 24, // expires in 24 hours
  //   });
  
  //   return presignedUrl;
  // };



  const handleImageUpload = async (image) => {
    const imageBytes = Buffer.from(image, 'base64');
    const params = {
      Bucket: BUCKET_NAME,
      Key: `${Date.now()}.jpg`,
      Body: imageBytes,
      ACL: 'public-read', // Make object publicly accessible
    };
  
    // Upload the image
    const data = await s3Client.send(new PutObjectCommand(params));
  
    // Construct the permanent URL
    const permanentUrl = `https://${BUCKET_NAME}.s3.${REGION_NAME}.amazonaws.com/${params.Key}`;
  return permanentUrl.replace(/\/$/, ''); 
  };

  // Handle content change
  const handleChange =  (value) => {
      setContent(value);
  };

//   useEffect(() => {
    
//   if (Object.keys(uploadedImages).length > 0) {
//     console.log('Harsh.......')
//     const updatedValue = content.replace(/<img[^>]*src="([^"]+)"[^>]*>/g, (match, src) => {
//       return `<img src="${uploadedImages[src] || src}" />`;
//     });
//     setContent(updatedValue);
//   }
// }, [uploadedImages, content]);

const handleSubmit = async (e) => {
  e.preventDefault();


  let thumbnailUrl;
  if (thumbnail) {
    thumbnailUrl = await handleImageUpload(thumbnail.split(',')[1]);
  }

  // Find all image tags in the content
  const images = content.match(/<img[^>]*src="([^"]+)"[^>]*>/g);
  let updatedContent = content;

  if (images) {
    await Promise.all(
      images.map(async (image) => {
        const base64Image = image.match(/src="([^"]+)"/)[1];

        if (base64Image.startsWith('data:image')) {
          const uploadedUrl = await handleImageUpload(base64Image.split(',')[1]);
          updatedContent = updatedContent.replace(/src=\\"/g, 'src="');
          updatedContent = updatedContent.replace(base64Image, uploadedUrl);
          
        }
      })
    );
  }

  // Create the blog post object with updated content
  const blogPost = { blogTitle:title, blogContent: updatedContent, category,userId:'123',operation:'create' ,active:true,thumbnailUrl:thumbnailUrl};
  const tempData=JSON.stringify(blogPost);
  console.log(tempData);
  try {
    const response = await fetch(LAMBDA_URL, {
      method: 'POST',
      body: JSON.stringify(blogPost),
    });
    console.log(response);
    if (!response.ok) {
      throw new Error('Failed to submit form');
    }

    const result = await response.json();
    console.log(result);
    setResponseMessage('Form submitted successfully!');
    navigate('/admin-home', { replace: true });
  } catch (error) {
    setResponseMessage('Error submitting form: ' + error.message);
  }
  
  console.log('Blog Post:', blogPost);
};


  const categories = [
    { value: '0', label: 'Price Action' },
    { value: '1', label: 'Equity Research' },
  ];

  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setThumbnail(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
    <AdminNav />
    <Container className="mt-5">
      <h2>Add a New Blog Post</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formTitle" className='mb-4'>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            
            placeholder="Enter blog title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formThumbnail" className='mb-4'>
            <Form.Label>Thumbnail</Form.Label>
            <Form.Control 
              type="file" 
              accept="image/*"
              onChange={handleThumbnailChange}
            />
            {thumbnail && (
              <Image src={thumbnail} thumbnail width={100} height={100} />
            )}
          </Form.Group>
        <Form.Group controlId="category" className='mb-4'>
          <Form.Label>Category</Form.Label>
          <Form.Select
            aria-label="Select category"
            name="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Select category</option>
            {categories.map((category) => (
              <option key={category.value} value={category.value}>
                {category.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="formBody" className='mb-4'>
        <Form.Label >Blog Details</Form.Label>
          <ReactQuill
            value={content}
            onChange={handleChange}
            placeholder="Write something amazing..."
            modules={AddBlogPage.modules}
            formats={AddBlogPage.formats}
          />
        </Form.Group>

       

        <Button variant="primary" type="submit">
          Submit
        </Button>
      </Form>
    </Container>
    </>
  );
};

AddBlogPage.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['link', 'image', 'video'],
    ['clean'] 
  ],
};

AddBlogPage.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet',
  'link', 'image', 'video'
];

export default AddBlogPage;