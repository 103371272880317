import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { QUOTES } from '../utils/constants';



const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const QuoteText = styled.p`
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

const ShimmerWrapper = styled.div`
  display: inline-block;
  width: 200px;
  height: 80px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #f0f0f0;
  position: relative;
`;

const ShimmerInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  opacity: 0.5;
  border-radius: 10px;
  animation: shimmer 2s infinite;
  @keyframes shimmer {
    0% {
      transform: scale(0.5);
      opacity: 0.5;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.5);
      opacity: 0.5;
    }
  }
`;

const Shimmer = () => {
  const [randomQuote, setRandomQuote] = useState('');

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * QUOTES.length);
    setRandomQuote(QUOTES[randomIndex]);
  }, []);

  return (
    <Container>
      <QuoteText>{randomQuote}</QuoteText>
      <ShimmerWrapper>
        <ShimmerInner />
      </ShimmerWrapper>
    </Container>
  );
};

export default Shimmer;