import React from 'react';
import { useNavigate } from 'react-router-dom';
import MyNav from './MyNav';

const Academy = () => {
    const navigate = useNavigate();
    const handelNavigate=()=>{
        navigate('/contact', { replace: true });
    }
    return (
        <>
        <MyNav />
        <div style={{
            fontFamily: 'Arial, sans-serif',
            color: '#333',
            backgroundColor: '#f8f8f8',
            textAlign: 'center',
            padding: '20px',
        }}>
            <div style={{ marginTop: '40px' }}>
                <h1 style={{ fontSize: '2.5em', color: '#4caf50', margin: '20px 0 10px' }}>Learn Price Action With Us</h1>
                <p style={{ fontSize: '1.2em', color: '#333', margin: '10px 0' }}>Your journey to trading success starts here.</p>
                <button onClick={handelNavigate} style={{
                    backgroundColor: '#4caf50',
                    color: 'white',
                    border: 'none',
                    padding: '15px 30px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    fontSize: '1em',
                    margin: '20px 0'
                }}>Enroll Now</button>
               
            </div>
        </div>
        </>
    );
};

const navLinkStyle = {
    margin: '0 10px',
    textDecoration: 'none',
    color: '#333',
    fontSize: '1em'
};

export default Academy;
