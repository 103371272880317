import React, { useEffect, useState } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { S3Client, PutObjectCommand, GetObjectCommand } from '@aws-sdk/client-s3';
import { Buffer } from 'buffer';
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import AdminNav from './AdminNav';
import { useNavigate, useParams } from 'react-router-dom';
import Shimmer from './Shimmer';
import { LAMBDA_URL } from '../utils/constants';
import { Image } from 'react-bootstrap';


const EditBlog = () => {
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [content, setContent] = useState('');
  const [uploadedImages, setUploadedImages] = useState({});
  const [postContent, setPostContent] = useState('');
  const [responseMessage, setResponseMessage] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const blogId = params.id;
  const [loading, setLoading] = useState(true);
  const [thumbnail, setThumbnail] = useState(null);


  // AWS credentials
  const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
  const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
  const REGION_NAME = process.env.REACT_APP_REGION_NAME;
  const BUCKET_NAME = process.env.REACT_APP_BUCKET_NAME;

  // Create S3 client
  const s3Client = new S3Client({
    region: REGION_NAME,
    credentials: {
      accessKeyId: AWS_ACCESS_KEY_ID,
      secretAccessKey: AWS_SECRET_ACCESS_KEY,
    },
  });

  
  const handleImageUpload = async (image) => {
    const imageBytes = Buffer.from(image, 'base64');
    const params = {
      Bucket: BUCKET_NAME,
      Key: `${Date.now()}.jpg`,
      Body: imageBytes,
      ACL: 'public-read', 
    };


  
    
    const data = await s3Client.send(new PutObjectCommand(params));
  
    
    const permanentUrl = `https://${BUCKET_NAME}.s3.${REGION_NAME}.amazonaws.com/${params.Key}`;
  return permanentUrl.replace(/\/$/, ''); 
  };




  // Handle content change
  const handleChange =  (value) => {
    console.log(content)
      setContent(value);
  };


const handleSubmit = async (e) => {
  e.preventDefault();


  let thumbnailUrl;
  if (thumbnail) {
    thumbnailUrl = await handleImageUpload(thumbnail.split(',')[1]);
  }
  // Find all image tags in the content
  const images = content.match(/<img[^>]*src="([^"]+)"[^>]*>/g);
  let updatedContent = content;

  if (images) {
    await Promise.all(
      images.map(async (image) => {
        const base64Image = image.match(/src="([^"]+)"/)[1];

        if (base64Image.startsWith('data:image')) {
          // Upload the image and get the S3 URL
          const uploadedUrl = await handleImageUpload(base64Image.split(',')[1]);

          updatedContent = updatedContent.replace(/src=\\"/g, 'src="');
          // Replace the base64 string with the uploaded URL in the content
          updatedContent = updatedContent.replace(base64Image, uploadedUrl);
          // setUploadedImages((prev) => ({ ...prev, [base64Image]: uploadedUrl }));
        }
      })
    );
  }

  // Create the blog post object with updated content
  const blogPost = {blogId, blogTitle:title, blogBody: updatedContent, category,userId:'123',operation:'update_blog' ,active:true,thumbnailUrl:thumbnailUrl};
  const tempData=JSON.stringify(blogPost);
  console.log(tempData);
  try {
    const response = await fetch(LAMBDA_URL, {
      method: 'POST',
      body: JSON.stringify(blogPost),
    });
    console.log(response);
    if (!response.ok) {
      throw new Error('Failed to submit form');
    }

    const result = await response.json();
    console.log(result);
    setResponseMessage('Form submitted successfully!');
  } catch (error) {
    setResponseMessage('Error submitting form: ' + error.message);
  }
  
  console.log('Blog Post:', blogPost);
  navigate('/admin-home', { replace: true });
};


  const categories = [
    { value: '0', label: 'Price Action' },
    { value: '1', label: 'Equity Research' },
  ];


  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(LAMBDA_URL, {
          method: 'POST',
          body: JSON.stringify({
            operation: 'show_blog',
            blogId: blogId,
            userId: '123',
          }),
        });

        const data = await response.json();
        console.log(data);
        setTitle(data.blogTitle);
        setContent(data.blogBody);
        setCategory(data.category);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog:', error);
        setLoading(false);
      }
    };

    fetchBlog();
  }, [blogId]);


  const handleThumbnailChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setThumbnail(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };


  if (loading) {
    return (
    <>
    <AdminNav />
    <Shimmer />
    </>);
  }

  return (
    <>
    <AdminNav />
    <Container className="mt-5">
      <h2>Edit Blog Post</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formTitle" className='mb-4'>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter blog title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>


        <Form.Group controlId="formThumbnail" className='mb-4'>
            <Form.Label>Thumbnail</Form.Label>
            <Form.Control 
              type="file" 
              accept="image/*"
              onChange={handleThumbnailChange}
            />
            {thumbnail && (
              <Image src={thumbnail} thumbnail width={100} height={100} />
            )}
        </Form.Group>
        <Form.Group controlId="category" className='mb-4'>
          <Form.Label>Category</Form.Label>
          <Form.Select
            aria-label="Select category"
            name="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
          >
            <option value="">Select category</option>
            {categories.map((category) => (
              <option key={category.value} value={category.value}>
                {category.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group controlId="formBody" className='mb-4'>
        <Form.Label >Blog Details</Form.Label>
          <ReactQuill
            value={content}
            onChange={handleChange}
            placeholder="Write something amazing..."
            modules={EditBlog.modules}
            formats={EditBlog.formats}
          />
        </Form.Group>

       

        <Button variant="primary" type="submit">
          Update
        </Button>
      </Form>
    </Container>
    </>
  );
};

EditBlog.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    ['link', 'image', 'video'],
    ['clean'] 
  ],
};

EditBlog.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet',
  'link', 'image', 'video'
];

export default EditBlog;