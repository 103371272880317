import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';



const AdminLogin = ()=>{

    const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const [invalidCredentials, setInvalidCredentials] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (username === 'admin' && password === 'admin') {
        setInvalidCredentials(false);
        navigate('/admin-home', { replace: true });
    } else {
      setInvalidCredentials(true);
    }
  };

  return (
    <Form onSubmit={handleSubmit} className="d-flex justify-content-center align-items-center vh-100" style={{
      background: 'linear-gradient(to bottom right, #f7f7f7, #e9e9e9)',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }}>
      <div style={{
        width: '300px',
        textAlign: 'center',
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '10px',
        boxShadow: '0 0 10px rgba(0,0,0,0.1)'
      }}>
        <h2 className="mb-4">Login</h2>

        <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
          <Form.Label column sm="4" className="small text-muted">
            Username
          </Form.Label>
          <Col sm="8">
            <Form.Control 
              type="text" 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
              placeholder="Username" 
              size="sm" 
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
          <Form.Label column sm="4" className="small text-muted">
            Password
          </Form.Label>
          <Col sm="8">
            <Form.Control 
              type="password" 
              value={password} 
              onChange={(e) => setPassword(e.target.value)} 
              placeholder="Password" 
              size="sm" 
            />
          </Col>
        </Form.Group>

        {invalidCredentials && (
          <Form.Label column sm="12" className="small text-danger mb-3">
            Invalid credentials
          </Form.Label>
        )}

        <Button variant="primary" size="sm" type="submit">
          Login
        </Button>
      </div>
    </Form>
    
    );
}

export default AdminLogin;