import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Card } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AdminNav from './AdminNav';
import { useParams } from 'react-router-dom';
import MyNav from './MyNav';
import { LAMBDA_URL } from '../utils/constants';
import Shimmer from './Shimmer';


const ViewBlog = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [createdDate,setCreatedDate]=useState('');
  const params = useParams();
  const blogId = params.id;
  const [loading, setLoading] = useState(true);

  console.log(blogId)

  const categories = {
    '0': 'Price Action',
    '1': 'Equity Research',
  };

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(LAMBDA_URL, {
          method: 'POST',
          body: JSON.stringify({
            operation: 'show_blog',
            blogId: blogId,
            userId: '123',
          }),
        });

        const data = await response.json();
        console.log(data);
        setTitle(data.title);
        setContent(data.blogBody);
        setCategory(data.category);
        setCreatedDate(data.createdAt);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog:', error);
        setLoading(false);
      }
    };

    fetchBlog();
  }, [blogId]);


  if (loading) {
    return (
    <>
    <MyNav />
    <Shimmer />
    </>);
  }

  return (
    <>
      <MyNav />
      <Container className="mt-5">
        <Card>
          <Card.Header>
            <p className="fs-1">{title}</p>
            <p>{createdDate}</p>
            <p>{categories[category]}</p>
          </Card.Header>
          <Card.Body>
            <ReactQuill
              value={content}
              readOnly={true}
              modules={{ toolbar: false }}
            />
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default ViewBlog;