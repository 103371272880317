import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { LAMBDA_URL } from '../utils/constants';
import AdminNav from './AdminNav';
import Shimmer from './Shimmer';

const ViewContacts = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const blogPost={
    "operation": "list_all_contacts",
    "category": "",
    "userId": "123"
 }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(LAMBDA_URL, {
            method: 'POST',
            body: JSON.stringify(blogPost),
          });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setData(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  if (loading) return <Shimmer />;

  return (
    <>
    <AdminNav />
    <Table striped bordered hover variant="dark">
      <thead>
        <tr>
          <th>ID</th>
          <th>Full Name</th>
          <th>Email</th>
          <th>Message</th>
          <th>Created On</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.contactId}>
            <td>{item.contactId}</td>
            <td>{item.fullName}</td>
            <td>{item.emailId}</td>
            <td>{item.message}</td>
            <td>{item.createdAt}</td>
          </tr>
        ))}
      </tbody>
    </Table>
    </>
  );
};

export default ViewContacts;