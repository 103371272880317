import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MyNav from './MyNav';

const ThankYouMessage = () => {
  return (
    <>
    <MyNav />
    <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <Row>
        <Col xs={12} md={6} className="text-center">
          <h2>Thank you for contacting us!</h2>
          <p>Our team member will connect with you soon.</p>
        </Col>
      </Row>
    </Container>
    </>
  );
};

export default ThankYouMessage;