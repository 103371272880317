import { Container, Nav, Navbar } from "react-bootstrap";
import { Button } from "react-bootstrap";

const MyNav=()=>{
    return(
        <Navbar bg="primary" expand="md" variant="dark" className="mb-3">
        <Container fluid>
          <Navbar.Brand href="/">Equity Gurukul</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav>
              <Nav.Link href={"/"}>Home</Nav.Link>
              <Nav.Link href={"/price-actions"}>Price Action</Nav.Link>
              <Nav.Link href={"/equity-research"}>Equity Research</Nav.Link>
              <Nav.Link href={"/academy"}><Button variant="light">Academy</Button></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}

export default MyNav;