import logo from './logo.svg';
import './App.css';
import MyNav from './components/MyNav';
import ViewAllBlogs from './components/ViewAllBlogs';
import Shimmer from './components/Shimmer';
import Academy from './components/Academy';
import ContactForm from './components/ContactForm';

function App() {
  return (
    <div className="App">
      {/* <MyNav /> */}
      <ViewAllBlogs />
      {/* <Shimmer /> */}
      {/* <Academy /> */}
      {/* <ContactForm /> */}
    </div>
  );
}

export default App;
