import DataTable from 'react-data-table-component';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import AdminLogin from './AdminLogin';
import AdminNav from './AdminNav';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Shimmer from './Shimmer';
import { LAMBDA_URL } from '../utils/constants';



const ViewPriAct = () => {
    const navigate = useNavigate();

    const Actions = ({id}) => (
        <div>
          <Button onClick={() => handleEdit(id)}>Edit</Button> {`  `}
          <Button variant="danger" onClick={() => handleDelete(id)} >Delete</Button>
        </div>
      );

    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchBlogs = async () => {
        try {
          const response = await fetch(LAMBDA_URL, {
            method: 'POST',
            body: JSON.stringify({
              operation: 'list_all_blogs',
              category: '',
              userId: '123',
            }),
          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
    
          const data = await response.json();
          setBlogs(data);
          console.log(data);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching blogs:', error);
          setLoading(false);
        }
      };
    // const Actions = ({id}) => (
    //     <div>
    //       <Button onClick={() => handleEdit(id)}>Edit</Button> {`  `}
    //       <Button variant="danger" onClick={() => handleDelete(id)} >Delete</Button>
    //     </div>
    //   );

      const handleEdit = (id) => {
        navigate(`/edit-blog/${id}`, { replace: true });
      };

      const handleDelete = async (id) => {
        const apiEndpoint = LAMBDA_URL;
        const requestData = {
          "operation": "delete_blog",
          "blogId": id,
          "userId": "123"
        };
      
        try {
          const response = await fetch(apiEndpoint, {
            method: 'POST',
            body: JSON.stringify(requestData)
          });
      
          const data = await response.json();
          console.log(data);
          fetchBlogs();
        } catch (error) {
          console.error('Error:', error);
        }
      };

      useEffect(() => {
        fetchBlogs();
        
      }, []);

  const columns = [
    {
      name: 'Id',
      selector: row => row.blogId,
      sortable: true,
      minWidth: '150px',
      
    },
    {
      name: 'Title',
      selector: row => row.blogTitle,
      sortable: true,
      wrap:true
    },
    {
        name:'Actions',
        selector: row => <Actions id={row.blogId}/>,
        minWidth: '300px', 
    },
    {
        name:'Created On',
        selector: row => row.createdAt, 
    },
  ];

  
  const handleAddBlog =()=>{
    navigate('/add-blog', { replace: true });
  };

  if (loading) {
    return (
    <>
    <AdminNav />
    <Shimmer />
    </>);
  }
  return (
    <div>
      <AdminNav /> 
      <div style={{paddingTop:'100px'}}>
        <Button style={{marginLeft:'150px'}} onClick={handleAddBlog} >Add Blog</Button>
    <Container fluid className="h-100">
      <Row className="d-flex justify-content-center align-items-center h-100">
        <Col md={12} lg={12} xl={8}>
          <DataTable
            columns={columns}
            data={blogs}
            pagination
          />
        </Col>
      </Row>
    </Container>
    </div> 
    </div>
  );
};

export default ViewPriAct;