import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AdminLogin from './components/AdminLogin';
import ViewPriAct from './components/ViewPriAct';
import AdminNav from './components/AdminNav';
import AddBlogPage from './components/AddBlogPage';
import ViewBlog from './components/ViewBlog';
import EditBlog from './components/EditBlog';
import ViewAllBlogs from './components/ViewAllBlogs';
import MyNav from './components/MyNav';
import Academy from './components/Academy';
import ViewContacts from './components/ViewContacts';
import ContactForm from './components/ContactForm';
import ThankYouMessage from './components/ThankYouMessage';
const appRouters=createBrowserRouter([
  {
      path:"/",
      element:<App />,
  },
  {
    path:'/admin-login',
    element:<AdminLogin />,
  },
  {
    path:'/admin-home',
    element:<ViewPriAct />
  },
  {
    path:'/add-blog',
    element:<AddBlogPage />

  },
  {
    path:'/view-blog/:id',
    element:<ViewBlog />
  },
  {
    path:'/edit-blog/:id',
    element:<EditBlog />
  },
  {
    path:"/price-actions" ,
    element:<>
    {/* <MyNav /> */}
    <ViewAllBlogs category="0" />
    </>
  },

  {
    path:"/equity-research" ,
    element:<>
    {/* <MyNav /> */}
    <ViewAllBlogs category="1" />
    </>
  },

  {
    path:"/academy" ,
    element:<Academy />
  },
  {
    path:"/view-users" ,
    element:<ViewContacts />
  },
  {
    path:"/contact" ,
    element:<ContactForm />
  },
  {
    path:"/message" ,
    element:<ThankYouMessage />
  },

]);




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={appRouters} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
