import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { LAMBDA_URL } from '../utils/constants';

function ContactForm() {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState(null);
  const navigate = useNavigate();

  const handleSubmit =async (event) => {
    event.preventDefault();
    console.log('Form submitted:', fullName, email, message);
    const contactUs={fullName,email,message,operation:'create_contact'}
    // console.log(contactUs);

    try {
      const response = await fetch(LAMBDA_URL, {
        method: 'POST',
        body: JSON.stringify(contactUs),
      });
      console.log(response);
      if (!response.ok) {
        throw new Error('Failed to submit form');
      }
      
      const result = await response.json();
      console.log(result);

      navigate('/message', { replace: true });
      // setResponseMessage('Form submitted successfully!');
    } catch (error) {
      setResponseMessage('Error submitting form: ' + error.message);
      
    }


  };

  return (
    <Container className="contact-form" style={containerStyles}>
      <Row>
        <Col md={12}>
          <h2 className="text-center mb-4" style={headingStyles}>Talk to Us</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="mx-auto">
          <Form onSubmit={handleSubmit} style={formStyles}>
            <Form.Group controlId="fullName">
              <Form.Label style={labelStyles}>Full Name:</Form.Label>
              <Form.Control
                type="text"
                value={fullName}
                onChange={(event) => setFullName(event.target.value)}
                placeholder="Enter your full name"
                required
                style={inputStyles}
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label style={labelStyles}>Email:</Form.Label>
              <Form.Control
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Enter your email"
                required
                style={inputStyles}
              />
            </Form.Group>
            <Form.Group controlId="message">
              <Form.Label style={labelStyles}>Message:</Form.Label>
              <Form.Control
                as="textarea"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                placeholder="Enter your message"
                required
                style={textareaStyles}
              />
            </Form.Group>
            <Button variant="primary" type="submit" style={buttonStyles}>
              Talk to Us
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

const containerStyles = {
  maxWidth: '800px',
  margin: '40px auto',
  padding: '20px',
  backgroundColor: '#f9f9f9',
  border: '1px solid #ccc',
  borderRadius: '10px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
};

const headingStyles = {
  marginBottom: '20px',
  color: '#333'
};

const formStyles = {
  maxWidth: '100%',
  padding: '20px'
};

const labelStyles = {
  display: 'block',
  marginBottom: '5px',
  fontWeight: 'bold'
};

const inputStyles = {
  width: '100%',
  padding: '10px',
  marginBottom: '20px',
  border: '1px solid #ccc',
  borderRadius: '5px'
};

const textareaStyles = {
  ...inputStyles,
  height: '150px',
  resize: 'none'
};

const buttonStyles = {
  padding: '10px 20px',
  backgroundColor: '#4CAF50',
  color: '#fff',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer'
};

export default ContactForm;