import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_IMAGE_URL, LAMBDA_URL } from '../utils/constants';
import MyNav from './MyNav';
import Shimmer from './Shimmer';

function ViewAllBlogs({ category }) {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchBlogs = async () => {
    try {
      const response = await fetch(LAMBDA_URL, {
        method: 'POST',
        body: JSON.stringify({
          operation: 'list_all_blogs',
          category: category,
          userId: '123',
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setBlogs(data);
      setFilteredBlogs(data);
      setLoading(false);
      console.log(data);
    } catch (error) {
      console.error('Error fetching blogs:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
    
  }, [category]);

  const categories = {
    '0': 'Price Action',
    '1': 'Equity Research',
  };

  const handleClickPA = () => {
    const tempData = blogs.filter((b) => b.category === '0');
    setFilteredBlogs(tempData);
  };

  const handleClickER = () => {
    const tempData = blogs.filter((b) => b.category === '1');
    setFilteredBlogs(tempData);
  };

  const handleClickHome = () => {
    setFilteredBlogs(blogs);
  };

  const openBlog = (id) => {
    navigate(`/view-blog/${id}`);
  };

  if (loading) {
    return (
    <>
    <MyNav />
    <Shimmer />
    </>);
  }

  return (
    <>
      <MyNav />
      <Container fluid className="p-4">
        <Row xs={1} md={3} lg={3} className="g-4">
          {filteredBlogs.map((blog) => (
            <Col key={blog?.blogId} md={4} lg={4} className="d-flex align-items-stretch">
              <Card className="h-100" onClick={() => openBlog(blog?.blogId)} style={{cursor:'pointer'}}>
              <Card.Img 
                variant="top" 
                
                style={{ maxHeight: '300px',  }} 
                src={blog?.thumbnailUrl?blog?.thumbnailUrl:DEFAULT_IMAGE_URL} 
                />
                <Card.Body>
                  <Card.Title>{blog?.blogTitle}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">{blog?.createdAt}</Card.Subtitle>
                  <Card.Text>
                    {blog?.blogBodyTrimmed}
                  </Card.Text>
                  <Card.Link>{categories[blog?.category]}</Card.Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default ViewAllBlogs;