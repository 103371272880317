export const LAMBDA_URL='https://7k2z2yyyxhpmsklxy4onstaw4q0kvuhe.lambda-url.us-east-1.on.aws/';
export const DEFAULT_IMAGE_URL='https://equitygurukul-bucket.s3.us-east-1.amazonaws.com/thumbnail_image/IMG-20241102-WA0006.jpg';

export const QUOTES = [
    "The biggest risk is not taking any risk.\" - Mark Zuckerberg",
    "Price is what you pay. Value is what you get.\" - Warren Buffett",
    "Bull markets are born on pessimism, grown on skepticism, mature on optimism and die on euphoria.\" - John Templeton",
    "Investing should be more like watching paint dry or watching grass grow.\" - Paul Samuelson",
    "The key to investing is not how much you know but understanding what you don't know.\" - Peter Bernstein",
    "Never invest in a business you cannot understand.\" - Warren Buffett",
    "Markets can remain irrational longer than you can remain solvent.\" - John Maynard Keynes",
    "Risk comes from not knowing what you're doing.\" - Warren Buffett",
    "An investment in knowledge pays the best interest.\" - Benjamin Franklin",
    "It's far better to buy a wonderful company at a fair price.\" - Warren Buffett",
    "The stock market is a device to transfer money from the impatient to the patient.\" - Warren Buffett",
    "Investing is a game of psychology, not economics.\" - Robert Kiyosaki",
    "Diversification is protection against ignorance.\" - Warren Buffett",
    "The most valuable commodity in investment is information.\" - Nelson Mandela",
    "Compound interest is the eighth wonder of the world.\" - Albert Einstein",
    "Investing for the long haul avoids short-term volatility.\" - Warren Buffett",
    "Business quality is more important than stock price.\" - Warren Buffett",
    "Investors must assess risk tolerance.\" - Benjamin Graham",
    "The intelligent investor is a realist who sells to optimists and buys from pessimists.\" - Jason Zweig",
    "The four most expensive words in the English language are 'This time it's different.'\" - John Templeton",
    "Investing requires discipline and patience.\" - Warren Buffett",
    "Avoid emotional investment decisions.\" - Warren Buffett",
    "Invest in great businesses at reasonable prices.\" - Warren Buffett",
    "Focus on quality, not quantity.\" - Peter Lynch",
    "The stock market rewards long-term investors.\" - Warren Buffett",
    "Investing is about understanding human psychology.\" - Robert Kiyosaki",
    "Investors prioritize risk management.\" - Ray Dalio",
    "Seek durable competitive advantages.\" - Warren Buffett",
    "Investing requires a deep understanding of human nature.\" - Robert Kiyosaki",
    "Investing in personal growth is vital.\" - Robert Kiyosaki",
    "Investors prioritize business quality.\" - Warren Buffett",
    "The stock market reflects economic reality.\" - Warren Buffett",
    "Investing requires thoughtful decision-making.\" - Joel Greenblatt",
    "Investors focus on strong financials.\" - Warren Buffett",
    "Investing in knowledge is essential.\" - Benjamin Franklin",
    "Prioritize competitive advantages.\" - Warren Buffett",
    "Investing demands patience.\" - Warren Buffett",
    "The stock market is driven by human emotions.\" - Robert Kiyosaki",
    "Investors maintain discipline.\" - Joel Greenblatt",
    "Quality businesses yield returns.\" - Warren Buffett",
    "Assess management's track record.\" - Peter Lynch",
    "Understand uncertainty.\" - Michael Mauboussin",
    "Market volatility demands adaptability.\" - John Templeton",
    "Focus on the business model.\" - Warren Buffett",
    "Informed decisions drive investing.\" - Benjamin Graham",
    "Prioritize talent and skill.\" - Warren Buffett",
    "Personal development matters.\" - Robert Kiyosaki",
    "Investor sentiment impacts markets.\" - Warren Buffett",
    "Consider industry trends.\" - Peter Lynch",
    "Discipline and patience ensure success.\" - Warren Buffett",
    "Investing is about probability.\" - Michael Mauboussin",
    "Long-term perspective is crucial.\" - Warren Buffett",
    "Invest in education.\" - Warren Buffett",
    "Avoid get-rich-quick schemes.\" - Warren Buffett",
    "Investing requires resilience.\" - Ray Dalio",
    "Understand value.\" - Benjamin Graham",
    "Invest in yourself.\" - Warren Buffett",
    "Focus on the future.\" - Elon Musk",
    "Investing demands adaptability.\" - Peter Lynch",
    "Quality over quantity.\" - Warren Buffett",
    "Risk management is key.\" - Ray Dalio",
    "Invest for the long haul.\" - Warren Buffett",
    "Business durability matters.\" - Warren Buffett",
    "Personal growth boosts investing.\" - Robert Kiyosaki",
    "Investing requires vision.\" - Peter Thiel",
    "Market fluctuations provide opportunities.\" - Warren Buffett",
    "Investing demands critical thinking.\" - Joel Greenblatt",
    "Competitive advantage ensures success.\" - Warren Buffett",
    "Investor education is vital.\" - Benjamin Graham",
    "Investing requires strategic thinking.\" - Ray Dalio",
    "Long-term investing yields rewards.\" - Warren Buffett",
    "Business quality ensures returns.\" - Warren Buffett",
    "Investing demands continuous learning.\" - Robert Kiyosaki",
    "Market sentiment impacts investment.\" - John Templeton",
    "Invest in strong management.\" - Peter Lynch",
    "Diversification reduces risk.\" - Harry Markowitz",
    "Investing requires clarity.\" - Michael Mauboussin",
    "Investor discipline is crucial.\" - Joel Greenblatt",
    "Investing in innovation pays.\" - Elon Musk",
    "Understand market cycles.\" - John Templeton",
    "Investing demands persistence.\" - Robert Kiyosaki",
    "Quality investments ensure returns.\" - Warren Buffett",
    "Investor resilience matters.\" - Ray Dalio",
    "Focus on fundamentals.\" - Benjamin Graham",
    "Investing requires flexibility.\" - Peter Lynch",
    "Invest in talent.\" - Warren Buffett",
    "Long-term perspective ensures success.\" - Warren Buffett",
    "Investing demands dedication.\" - Joel Greenblatt",
    "Understand human psychology.\" - Robert Kiyosaki",
    "Market volatility demands strategy.\" - John Templeton",
    "Investing requires expertise.\" - Michael Mauboussin",
    "Business durability ensures returns.\" - Warren Buffett",
    "Investor education boosts returns.\" - Benjamin Graham",
    "Investing demands creativity.\" - Peter Thiel",
    "Long-term investing ensures rewards.\" - Warren Buffett",
    "Quality businesses yield long-term returns.\" - Warren Buffett",
    "Investor discipline ensures success.\" - Joel Greenblatt",
    "Investing requires vision.\" - Elon Musk",
    "Understand market trends.\" - Peter Lynch",
    "Investing successfully demands strategy.\" - Ray Dalio"
];