import { Container, Nav, Navbar } from "react-bootstrap";

const AdminNav=()=>{
    return(
        <Navbar bg="primary" expand="md" variant="dark" className="mb-3">
        <Container fluid>
          <Navbar.Brand href="/">Equity Gurukul</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav>
              <Nav.Link href={"/admin-home"}>Home</Nav.Link>
              <Nav.Link href={"/view-users"}>Contacted US</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
}

export default AdminNav;